// GLOBAL //
export const APP_NAME = 'Demeter Farming';
export const SORA_NAME = 'XOR';
export const TOKEN_NAME = 'DEO';
export const DEMETER_ADDRESS =
  '0x00f2f4fda40a4bf1fc3769d156fa695532eec31e265d75068524462c0b80f674';
export const DAI_ADDRESS =
  '0x0200060000000000000000000000000000000000000000000000000000000000';
export const XOR_ADDRESS =
  '0x0200000000000000000000000000000000000000000000000000000000000000';
export const IMAGE_STORAGE = 'https://data.cerestoken.io/storage/icons/';
export const DEMETER_LITEPAPER =
  'https://ceres-token.s3.eu-central-1.amazonaws.com/docs/Ceres%2BToken%2B-%2BDemeter%2BLitepaper%2B09.12.2021.pdf';
export const DEO_ARENA = 'https://deoarena.io/';

// SORA API //
export const SORA_API_TEST = 'wss://ws.stage.sora2.soramitsu.co.jp';
export const SORA_API = 'wss://ws.mof.sora.org';
export const POLKADOT_ACCOUNT = 'POLKADOT_ACCOUNT';
export const POLKADOT_EXTENSION = 'https://polkadot.js.org/extension/';

// BACKEND API //
export const PAIRS_API = 'https://data.cerestoken.io/api/pairs';
export const TOKENS_API = 'https://data.cerestoken.io/api/prices';
export const BACKEND_API = 'https://api.deotoken.com/api/demeter';
export const FEES_API = 'https://api.deotoken.com/api/fees';

// EXTERNAL LINKS //
export const SORA_BUILDER_PROGRAM =
  'https://medium.com/sora-xor/sora-builders-programme-979bea8831ed';
export const POLKASWAP = 'https://polkaswap.io/';

// ROUTE PERMALINKS //
export const FARMS_PERMALINK = '/farms';
export const POOLS_PERMALINK = '/staking';

// TOAST ID //
export const TOAST_ID = 'ToastID';

// CONSTANTS //
export const VIEW_MODE = 'view_mode';

// INPUT ERROR //
export const BALANCE_ERROR =
  'You must enter a number greater than zero and smaller or equal to your balance';
export const CONNECT_WALLET_ERROR = 'Please, connect your wallet first.';
