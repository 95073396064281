import styled from 'styled-components/macro';
import { colors } from '../../styles/colors';

export const Spinner = styled.div`
  border: 5px solid ${colors.white50};
  border-radius: 50%;
  border-top: 5px solid ${colors.orange};
  width: 60px;
  height: 60px;
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;

  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
