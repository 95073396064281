export const background = `#066452`;
export const white10 = `#ffffff10`;
export const white20 = `#ffffff20`;
export const white50 = `#ffffff50`;
export const white = `#ffffff`;
export const orange = `#FC6C09`;
export const orangeGradient = `linear-gradient(90.12deg, #FC6C09 -11.44%, #FFB781 99.9%)`;
export const green = `#438A7D`;
export const green2 = `#0A5143`;
export const green3 = `#006655`;
export const green4 = `#085150`;
export const green5 = `#28EBC8`;
export const green6 = `#0C5244`;
export const greenGradient = `linear-gradient(248.55deg, #006451 10.22%, #2BF1CD 98.12%)`;
export const greenGradient2 = `linear-gradient(180deg, #0AA78A 22.41%, #2BF1CD 100%)`;
export const greenGradient3 = `linear-gradient(269.59deg, #165549 10.39%, rgba(22, 85, 73, 0) 103.19%), #0A5143`;
export const containerBackground = `#184D42`;
export const divider = `rgba(255, 255, 255, 0.09)`;

export const colors = {
  background,
  white10,
  white20,
  white50,
  white,
  orange,
  orangeGradient,
  green,
  green2,
  containerBackground,
  divider,
  green3,
  green4,
  green5,
  green6,
  greenGradient,
  greenGradient2,
  greenGradient3,
};
