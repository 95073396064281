import React, { Suspense, lazy } from 'react';

import { Routes, Route } from 'react-router-dom';
import PageLoader from '../components/page_loader/page_loader';

const NotFound = lazy(() => import('../pages/not_found/not_found'));
const Farms = lazy(() => import('../pages/farms/farms'));
const Pools = lazy(() => import('../pages/pools/pools'));

const Router = () => (
  <Suspense fallback={<PageLoader fullscreen />}>
    <Routes>
      <Route exact path={'/'} element={<Farms />} />
      <Route exact path={'/farms'} element={<Farms />} />
      <Route exact path={'/staking'} element={<Pools />} />
      <Route path='*' element={<NotFound />} />
    </Routes>
  </Suspense>
);

export default Router;
