import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { PolkadotProvider } from './contexts/polkadot_context';
import history from './utils/history';

import store from './store';

import { BrowserRouter } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import { Provider } from 'react-redux';
import { TxFeesProvider } from 'contexts/tx_fees_context';

ReactDOM.render(
  <React.StrictMode>
    <IntlProvider locale='en'>
      <PolkadotProvider>
        <TxFeesProvider>
          <Provider store={store}>
            <BrowserRouter history={history}>
              <App />
            </BrowserRouter>
          </Provider>
        </TxFeesProvider>
      </PolkadotProvider>
    </IntlProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
