import styled from 'styled-components/macro';

export const WrapperSpinner = styled.div`
  align-items: center;
  display: flex;
  height: ${({ fullscreen }) =>
    fullscreen ? `calc(var(--vh, 1vh) * 100)` : `100%`};
  justify-content: center;
  width: 100%;
`;
