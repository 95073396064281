import { createGlobalStyle } from 'styled-components';
import { colors } from './colors';

const GlobalStyle = createGlobalStyle`
    *,
    *:before,
    *:after {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        -webkit-tap-highlight-color: transparent;
    }

    body {
        font-family: Montserrat, sans-serif;
        font-style: normal;
        font-size: 14px;
        overflow: hidden;
        background: ${colors.background};
    }

    :root { 
        --toastify-color-success: ${colors.green5};
        --toastify-icon-color-success: var(--toastify-color-success);
    }

    button, input, select, textarea {
        font-family: inherit !important;
        box-shadow: none !important;
        border: none !important
    }

    input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input[type=number] {
        -moz-appearance: textfield;
    }

    @media only screen and (min-width: 600px) {
        *,
        *:before,
        *:after {
            -webkit-tap-highlight-color: transparent;
            scrollbar-color: ${colors.orange} ${colors.green2};
            scrollbar-width: thin;
        }
        *::-webkit-scrollbar {
            width: 12px;
        }
        *::-webkit-scrollbar-track {
            background: ${colors.green2};
        }
        *::-webkit-scrollbar-thumb {
            background-color: ${colors.orange};
            border-radius: 6px;
            border: 3px solid ${colors.green2};
        }
    }

    @media only screen and (max-width: 1024px) {
        body {
            overflow: hidden;
        }
    }

    .react-modal-overlay {
        position: fixed;
        inset: 0px;
        background-color: #00000080 !important;
        z-index: 15;
    }

    /* REACT ICONS */
    .clickable-icon {
        cursor: pointer;
    }

    /* REACT TOGGLE */
    .react-toggle {
        touch-action: pan-x;
      
        display: inline-block;
        position: relative;
        cursor: pointer;
        background-color: transparent;
        border: 0;
        padding: 0;
      
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
      
        -webkit-tap-highlight-color: rgba(0,0,0,0);
        -webkit-tap-highlight-color: transparent;
    }
      
    .react-toggle-screenreader-only {
        border: 0;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }
      
    .react-toggle--disabled {
        cursor: not-allowed;
        opacity: 0.5;
        -webkit-transition: opacity 0.25s;
        transition: opacity 0.25s;
    }
      
    .react-toggle-track {
        width: 50px;
        height: 24px;
        padding: 0;
        border-radius: 30px;
        background-color: ${colors.white10};
        -webkit-transition: all 0.2s ease;
        -moz-transition: all 0.2s ease;
        transition: all 0.2s ease;
    }
      
    .react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
        background-color: ${colors.white10};
    }
      
    .react-toggle--checked .react-toggle-track {
        background: ${colors.orangeGradient};
    }
      
    .react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
        background-color: ${colors.white10};
    }
      
    .react-toggle-track-check {
        position: absolute;
        width: 14px;
        height: 10px;
        top: 0px;
        bottom: 0px;
        margin-top: auto;
        margin-bottom: auto;
        line-height: 0;
        left: 8px;
        opacity: 0;
        -webkit-transition: opacity 0.25s ease;
        -moz-transition: opacity 0.25s ease;
        transition: opacity 0.25s ease;
    }
      
    .react-toggle--checked .react-toggle-track-check {
        opacity: 1;
        -webkit-transition: opacity 0.25s ease;
        -moz-transition: opacity 0.25s ease;
        transition: opacity 0.25s ease;
    }
      
    .react-toggle-track-x {
        position: absolute;
        width: 10px;
        height: 10px;
        top: 0px;
        bottom: 0px;
        margin-top: auto;
        margin-bottom: auto;
        line-height: 0;
        right: 10px;
        opacity: 1;
        -webkit-transition: opacity 0.25s ease;
        -moz-transition: opacity 0.25s ease;
        transition: opacity 0.25s ease;
    }
      
    .react-toggle--checked .react-toggle-track-x {
        opacity: 0;
    }
      
    .react-toggle-thumb {
        transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
        position: absolute;
        top: 1px;
        left: 1px;
        width: 22px;
        height: 22px;
        border: 1px solid ${colors.white};
        border-radius: 50%;
        background-color: ${colors.white};
      
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
      
        -webkit-transition: all 0.25s ease;
        -moz-transition: all 0.25s ease;
        transition: all 0.25s ease;
    }
      
    .react-toggle--checked .react-toggle-thumb {
        left: 27px;
        border-color: ${colors.white};
    }
      
    .react-toggle--focus .react-toggle-thumb {
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
    }
      
    .react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
    }

    /*
	*
	* react-toastify styles
	*
	*/
    .Toastify__toast-container {
        max-width: 80vw;
        width: auto;
    }
    .Toastify__toast-body {
        padding: 15px;
    }
    .Toastify__toast--success {
        font-family: Montserrat, sans-serif;
        font-weight: 600;
        font-size: 16px;
        background: ${colors.containerBackground};
        color: ${colors.white};
    }
    .Toastify__toast--error {
        font-family: Montserrat, sans-serif;
        font-weight: 600;
        font-size: 16px;
        background: ${colors.containerBackground};
        color: ${colors.white};
    }
    .Toastify__close-button {
        color: ${colors.white} !important;
    }
`;

export default GlobalStyle;
