import { createContext, useEffect, useCallback, useRef, useState } from 'react';

import { WsProvider } from '@polkadot/rpc-provider/ws';
import { ApiPromise } from '@polkadot/api';
import { options } from '@sora-substrate/api';
import { web3Accounts, web3Enable } from '@polkadot/extension-dapp';

import axios from 'axios';

import {
  APP_NAME,
  BACKEND_API,
  DAI_ADDRESS,
  DEMETER_ADDRESS,
  POLKADOT_ACCOUNT,
  SORA_API,
  // SORA_API_TEST,
} from '../constants';

import { getNumberFromMultiplier } from '../utils/helpers';

const customTypes = {
  PoolData: {
    multiplier: 'u32',
    deposit_fee: 'Balance',
    is_core: 'bool',
    is_farm: 'bool',
    total_tokens_in_pool: 'Balance',
    rewards: 'Balance',
    rewards_to_be_distributed: 'Balance',
    is_removed: 'bool',
  },
  TokenInfo: {
    farms_total_multiplier: 'u32',
    staking_total_multiplier: 'u32',
    token_per_block: 'Balance',
    farms_allocation: 'Balance',
    staking_allocation: 'Balance',
    team_allocation: 'Balance',
    team_account: 'AccountId',
  },
  UserInfo: {
    pool_asset: 'AssetId',
    reward_asset: 'AssetId',
    is_farm: 'bool',
    pooled_tokens: 'Balance',
    rewards: 'Balance',
  },
};

export const PolkadotContext = createContext();

export const PolkadotProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [accounts, setAccounts] = useState(null);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [demeterPrice, setDemeterPrice] = useState(null);
  const [analytics, setAnalytics] = useState(null);

  const api = useRef(null);
  const refreshInterval = useRef(null);

  const saveSelectedAccount = (account) => {
    if (account !== selectedAccount) {
      localStorage.setItem(POLKADOT_ACCOUNT, JSON.stringify(account));
      setSelectedAccount(account);
    }
  };

  const setApi = useCallback(async () => {
    /** Connect to Sora network **/
    const provider = new WsProvider(SORA_API);

    const soraAPI = new ApiPromise(options({ types: customTypes, provider }));

    await soraAPI.isReady;
    api.current = soraAPI;
  }, []);

  const connectToPolkadotExtension = useCallback(async () => {
    const accountJSON = localStorage.getItem(POLKADOT_ACCOUNT);
    const account = JSON.parse(accountJSON) || null;

    // this call fires up the authorization popup
    const extensions = await web3Enable(APP_NAME);

    if (extensions.length !== 0) {
      // we are now informed that the user has at least one extension and that we
      // will be able to show and use accounts
      const allAccounts = await web3Accounts();

      if (allAccounts !== null && allAccounts.length > 0) {
        setAccounts(allAccounts);

        if (account !== null) {
          const accountsFiltered = allAccounts.filter(
            (acc) => acc?.meta?.name === account?.meta?.name
          );
          if (accountsFiltered.length > 0) {
            setSelectedAccount(account);
          }
        }
      }
    }
  }, []);

  const getDemeterPrice = useCallback(async () => {
    await api.current?.rpc?.liquidityProxy?.quote(
      0,
      DEMETER_ADDRESS,
      DAI_ADDRESS,
      '1000000000000000000',
      'WithDesiredInput',
      ['XYKPool'],
      'Disabled',
      (price) => {
        price = price.toHuman();
        if (price != null) {
          setDemeterPrice(getNumberFromMultiplier(price['amount'], true, 3));
        }
      }
    );
  }, [api]);

  const getAnalytics = useCallback(async () => {
    return axios
      .get(`${BACKEND_API}/supply-data`)
      .then((response) => {
        setAnalytics(response.data);
      })
      .catch(() => {});
  }, []);

  const setRefreshInterval = useCallback(async () => {
    refreshInterval.current = setInterval(() => {
      getDemeterPrice();
      getAnalytics();
    }, 15000);
  }, [getDemeterPrice, getAnalytics]);

  const init = useCallback(async () => {
    await setApi();
    await connectToPolkadotExtension();
    await getDemeterPrice();
    await getAnalytics();
    setRefreshInterval();
    setLoading(false);
  }, [
    setApi,
    connectToPolkadotExtension,
    getDemeterPrice,
    getAnalytics,
    setRefreshInterval,
  ]);

  useEffect(() => {
    init();

    return () => {
      api.current?.disconnect();
    };
  }, [init]);

  return (
    <PolkadotContext.Provider
      value={{
        api: api.current,
        accounts,
        selectedAccount,
        saveSelectedAccount,
        loading,
        demeterPrice,
        analytics,
      }}
    >
      {children}
    </PolkadotContext.Provider>
  );
};
