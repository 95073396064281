import { FEES_API } from 'constants';
import { TOKENS_API } from 'constants';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

export const TxFeesContext = createContext();

export const TxFeesProvider = ({ children }) => {
  const [fees, setFees] = useState([]);

  const fetchFees = useCallback(async () => {
    const [feesResponse, xorPriceResponse] = await Promise.all([
      await fetch(FEES_API),
      await fetch(`${TOKENS_API}/XOR`),
    ]);

    if (feesResponse.ok) {
      const feesJson = await feesResponse.json();
      const xorPrice = await xorPriceResponse.json();
      setFees(
        feesJson.map((f) => ({ ...f, price: (f.fee * xorPrice).toFixed(2) }))
      );
    }
  }, []);

  const getFee = useCallback(
    (type) => {
      if (fees.length === 0) {
        return 0;
      }

      switch (type) {
        case 'FARM':
          return fees.find((f) => f.type === 'Deposit');
        case 'HARVEST':
          return fees.find((f) => f.type === 'Rewards');
        case 'WITHDRAW':
          return fees.find((f) => f.type === 'Withdraw');
        default:
          return 0;
      }
    },
    [fees]
  );

  useEffect(() => {
    fetchFees();
  }, [fetchFees]);

  return (
    <TxFeesContext.Provider
      value={{
        getFee,
        fees,
      }}
    >
      {children}
    </TxFeesContext.Provider>
  );
};

export const useTxFees = () => {
  const contextValue = useContext(TxFeesContext);
  if (!contextValue) {
    throw new Error('useTxFees must be used within a TxFeesProvider');
  }
  return contextValue;
};
