import React from 'react';
import GlobalStyle from './styles/globalStyle';

import usePolkadot from './hooks/use_polkadot';

import PageLoader from './components/page_loader/page_loader';

import Router from './router';

import { toast, ToastContainer, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import useVieportHeight from './hooks/use_viewport_height';

toast.configure();

const App = () => {
  const { loading } = usePolkadot();

  useVieportHeight();

  return (
    <>
      <GlobalStyle />
      {loading ? <PageLoader fullscreen /> : <Router />}
      <ToastContainer
        limit={3}
        pauseOnFocusLoss={false}
        pauseOnHover={false}
        theme='dark'
        draggable={false}
        transition={Zoom}
      />
    </>
  );
};

export default App;
