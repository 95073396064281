import { createIntl, createIntlCache } from 'react-intl';

import multipliers from './multipliers';

import { Keyring } from '@polkadot/api';

import { IMAGE_STORAGE } from '../constants';

const cache = createIntlCache();

const intl = createIntl(
  {
    locale: 'en-US',
  },
  cache
);

export const formatWalletAddress = (address, formatDelimiter = 10) => {
  if (address && address.length > 20) {
    return `${address.substring(0, formatDelimiter)}-${address.substring(
      address.length - formatDelimiter,
      address.length
    )}`;
  }

  return '';
};

export const getAvatarTitle = (accountName) => {
  if (accountName !== null) {
    const nameSplit = accountName.split(' ');

    if (nameSplit.length > 1) {
      return (
        nameSplit[0].charAt(0).toUpperCase() +
        nameSplit[1].charAt(0).toUpperCase()
      );
    }

    return accountName.charAt(0);
  }

  return '';
};

export const checkNumberValue = (number) => {
  if (number && number !== 0 && number !== undefined && number !== Infinity) {
    return true;
  }

  return false;
};

export const priceFormat = (price, decimals = 2, roundNumber = false) => {
  try {
    if (price !== null) {
      let p = price;

      if (roundNumber) {
        const priceNumber = parseFloat(p);
        p = Math.floor(priceNumber * 100) / 100;
        return p.toFixed(2);
      }

      return intl.formatNumber(p, {
        maximumFractionDigits: decimals,
        minimumFractionDigits: 2,
      });
    }

    return '0.00';
  } catch {
    return '0.00';
  }
};

export const getNumberFromMultiplier = (
  number,
  format = true,
  decimals = 2
) => {
  try {
    const numberSplit = number?.split(' ');
    if (numberSplit?.length === 2) {
      try {
        const numberFloat =
          parseFloat(numberSplit[0]) * multipliers[numberSplit[1]];

        if (format) return priceFormat(numberFloat, decimals);
        return numberFloat;
      } catch {
        return '0.00';
      }
    }

    return '0.00';
  } catch {
    return '0.00';
  }
};

export const getEncodedAddress = (address) => {
  if (address) {
    const keyring = new Keyring();
    return keyring.encodeAddress(address, 69);
  }

  return '';
};

export const getImage = (token) => {
  return `${IMAGE_STORAGE}${token}.svg`;
};
